import styled from "styled-components";
import { mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  min-height: calc(100vh - 72px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 640px;
  padding: 64px 0;
  gap: 48px;

  ${mediaMax("lg")} {
    padding: 24px 0;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Info = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Avatar = styled.div`
  width: 40px;
  height: 40px;
  overflow: hidden;
  flex: 0 0 auto;

  border-radius: 9999px;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;
    object-position: center;
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const MetaRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0 8px;

  & > * {
    &:not(:first-child) {
      &::before {
        content: "|";

        margin-right: 8px;

        opacity: 0.5;
      }
    }
  }
`;
