import React from "react";
import { Table } from "./types";

export const TABLE: Table = {
  headings: [
    { text: "Reset", icon: "creditCard" },
    { text: "Cash Advance Apps", icon: "phone" },
    { text: "Traditional Credit Cards", icon: "creditCard" },
    { text: "Payday Loans", icon: "loan" },
  ],
  rows: [
    {
      title: "Free",
      icon: "party",
      cells: [
        { text: "Yes" },
        { text: "No", highlight: true },
        { text: "No", highlight: true },
        { text: "No", highlight: true },
      ],
    },
    {
      title: "Auto-budgeting",
      icon: "piggyBank",
      cells: [
        { text: "Yes" },
        { text: "No", highlight: true },
        { text: "No", highlight: true },
        { text: "No", highlight: true },
      ],
    },
    {
      title: "No credit check",
      icon: "fee",
      cells: [
        { text: "Yes" },
        { text: "Yes" },
        { text: "No", highlight: true },
        { text: "No", highlight: true },
      ],
    },
    {
      title: "No transfer fee",
      icon: "transfer",
      cells: [
        { text: "Yes" },
        {
          text: (
            <>
              Up to $5.99 per instant transfer&nbsp;<sup>2</sup>
            </>
          ),
          highlight: true,
        },
        {
          text: (
            <>
              3-5% of total advance&nbsp;<sup>3</sup>
            </>
          ),
          highlight: true,
        },
        { text: "No", highlight: true },
      ],
    },
    {
      title: "No monthly fee",
      icon: "calendar",
      cells: [
        { text: "Yes" },
        {
          text: (
            <>
              Up to $14.99&nbsp;<sup>2</sup>
            </>
          ),
          highlight: true,
        },
        { text: "Varies", highlight: true },
        { text: "Yes" },
      ],
    },
    {
      title: "No interest",
      icon: "dollarChart",
      cells: [
        { text: "Yes" },
        {
          text: (
            <>
              Up to 331% effective APR&nbsp;<sup>2</sup>
            </>
          ),
          highlight: true,
        },
        {
          text: (
            <>
              Up to 29.99% APR&nbsp;<sup>3</sup>
            </>
          ),
          highlight: true,
        },
        {
          text: (
            <>
              Up to 400% APR&nbsp;<sup>4</sup>
            </>
          ),
          highlight: true,
        },
      ],
    },
  ],
};
