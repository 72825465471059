import React from "react";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import logo from "assets/images/general/logo.svg";
import * as Styled from "./styled";
import { TABLE } from "../constants";
import CellContent from "../CellContent";

const Table = () => {
  const { headings, rows } = TABLE;

  return (
    <Styled.Table>
      <thead>
        <tr>
          <th></th>
          {headings.map((heading, index) => (
            <th key={index}>
              <Styled.CellContents>
                {index === 0 ? (
                  <Styled.ResetLogo src={logo} />
                ) : (
                  <UILabel.Small caps>{heading.text}</UILabel.Small>
                )}
              </Styled.CellContents>
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, rowIndex) => (
          <React.Fragment key={rowIndex}>
            {rowIndex > 0 && (
              <Styled.DividerRow>
                <td></td>
                <td>
                  <Styled.Divider />
                </td>
                <td colSpan={row.cells.length - 1}>
                  <Styled.Divider />
                </td>
              </Styled.DividerRow>
            )}

            <tr key={rowIndex}>
              <td>
                <Styled.CellContents>
                  <Icon name={row.icon} size="md" color="violet" />
                  <UILabel.XLarge>{row.title}</UILabel.XLarge>
                </Styled.CellContents>
              </td>
              {row.cells.map((cell, cellIndex) => (
                <td key={cellIndex}>
                  <Styled.CellContents>
                    <UILabel.XLarge>
                      <CellContent text={cell.text} />
                    </UILabel.XLarge>
                  </Styled.CellContents>
                </td>
              ))}
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Styled.Table>
  );
};

export default Table;
