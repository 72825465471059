import React from "react";

const QuotationMark = () => (
  <svg
    width="37"
    height="27"
    viewBox="0 0 37 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0.515625 26.5938V16.7031C0.515625 12.0156 1.625 8.3125 3.84375 5.59375C6.09375 2.875 9.35938 1.09375 13.6406 0.25V5.125C10.4531 6.125 8.3125 7.84375 7.21875 10.2812C6.65625 11.5625 6.42188 12.8906 6.51562 14.2656H13.875V26.5938H0.515625ZM35.8594 5.125C32.7031 6.09375 30.5781 7.84375 29.4844 10.375C28.8594 11.7188 28.6094 13.0156 28.7344 14.2656H36.0938V26.5938H22.7344V16.7031C22.7344 11.9531 23.875 8.23438 26.1562 5.54688C28.4688 2.85938 31.7031 1.09375 35.8594 0.25V5.125Z" />
  </svg>
);

export default QuotationMark;
