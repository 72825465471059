import React from "react";
import { CardType, TileBlockData } from "types/cms";
import { IconName } from "components/ui/Icon";

export const HEADER: {
  heading: React.ReactNode;
  body: React.ReactNode;
  eyebrow: React.ReactNode;
  bullets: { text: React.ReactNode; icon: IconName }[];
} = {
  heading: (
    <>
      Tap into financial wellness. You&nbsp;<em>earned</em>&nbsp;it.
    </>
  ),
  body: <>Get daily access to your income as you earn&nbsp;it.</>,
  eyebrow: "The income-linked smart card",
  bullets: [
    {
      text: (
        <>
          <strong>Free!</strong> No interest, monthly, transfer, or
          late&nbsp;fees
        </>
      ),
      icon: "moneyBillWave",
    },
    {
      text: "No credit check or security deposit",
      icon: "loan",
    },
    {
      text: "W2, gig and Social Security income supported",
      icon: "income",
    },
  ],
};

export const APP_HIGHLIGHT: {
  heading: React.ReactNode;
  body: React.ReactNode;
  disclaimer: React.ReactNode;
} = {
  heading: <>Budgeting on autopilot.</>,
  body: (
    <>
      Reset creates a dynamic spending limit tailored to you. We factor in your
      daily income, bank balance, and upcoming bills to help you better manage
      your&nbsp;budget.
    </>
  ),
  disclaimer: (
    <>
      Access up to $100 during your first pay cycle with Reset. With continued
      use of the Reset card, the spending limit begins adjusting daily based on
      your income, expenses, and other factors.
    </>
  ),
};

export const FEATURES: TileBlockData = {
  heading: (
    <>
      Reset is built around <em>you.</em>
    </>
  ),
  body: "Your financial health is what matters to us—we care because we come from the communities we aim to serve.",
  textSize: "large",
  tiles: [
    {
      type: CardType.icon,
      title: "Security first",
      body: "Your peace of mind is our priority. Reset uses modern security technology to keep your money safe and your data private.",
      iconName: "shield",
    },
    {
      type: CardType.icon,
      title: "Employer portable",
      body: (
        <>
          Connect your payroll in minutes! Enjoy the freedom to go after that
          higher paying job, or work multiple gigs. Reset goes
          where&nbsp;you&nbsp;go.<sup>1</sup>
        </>
      ),
      iconName: "briefcase",
    },
    {
      type: CardType.icon,
      title: "Keep your bank",
      body: (
        <>
          Love your bank or credit union? Stay right where you are. Access the
          Reset card without leaving your financial&nbsp;home.<sup>1</sup>
        </>
      ),
      iconName: "bank",
    },
  ],
};

export const FINANCIAL_INSTITUTIONS: {
  heading: React.ReactNode;
  body: React.ReactNode;
} = {
  heading: <>Are you a financial institution?</>,
  body: (
    <>
      Find out how some of the most customer-centric financial institutions are
      partnering&nbsp;with&nbsp;Reset.
    </>
  ),
};
