import React, { useRef, useState } from "react";
import UILabel from "components/ui/UILabel";
import Icon from "components/ui/Icon";
import Button, { ButtonLevel, ButtonSize } from "components/ui/Button";
import * as Styled from "./styled";
import { TABLE } from "../constants";
import { TableCell, TableHeading } from "../types";
import CellContent from "../CellContent";

interface CellProps extends TableCell {
  heading: TableHeading;
  isReset?: boolean;
}

const Cell: React.FC<CellProps> = ({ heading, text, highlight, isReset }) => (
  <Styled.CellWrapper $isReset={isReset} $highlight={highlight}>
    <Styled.CellHeading>{heading.text}</Styled.CellHeading>
    <Styled.CellValue $isLarge={typeof text === "string" && text.length <= 5}>
      <div>
        <CellContent text={text} />
      </div>
    </Styled.CellValue>
  </Styled.CellWrapper>
);

const Row = ({ title, cells, headings, selectedColumn }) => (
  <Styled.Row key={title.toString()}>
    <Styled.RowHeading>
      <UILabel.XLarge>{title}</UILabel.XLarge>
    </Styled.RowHeading>

    <Cell {...cells[0]} heading={headings[0]} isReset />

    <Cell {...cells[selectedColumn]} heading={headings[selectedColumn]} />
  </Styled.Row>
);

const CollapsedTable = () => {
  const [selectedColumn, setSelectedColumn] = useState(0);
  const wrapperRef = useRef(null);
  const { headings, rows } = TABLE;

  const onSelectComparison = (index) => {
    setSelectedColumn(index);

    if (wrapperRef.current) {
      wrapperRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Styled.Wrapper ref={wrapperRef}>
      {selectedColumn > 0 && (
        <Styled.Table>
          <Styled.TableBody>
            {rows.map((row) => (
              <Row
                key={row.title.toString()}
                {...row}
                headings={headings}
                selectedColumn={selectedColumn}
              />
            ))}
          </Styled.TableBody>
        </Styled.Table>
      )}

      <Styled.Buttons>
        <UILabel.Small bold caps>
          Tap to compare
        </UILabel.Small>

        <Styled.ButtonList>
          {headings.map((heading, headingIndex) => {
            if (headingIndex === 0 || headingIndex === selectedColumn)
              return null;

            return (
              <li key={heading.text.toString()}>
                <Button
                  onClick={() => onSelectComparison(headingIndex)}
                  size={ButtonSize.lg}
                  level={ButtonLevel.secondary}
                >
                  <Icon name={heading.icon} color="violet" />
                  {heading.text}
                </Button>
              </li>
            );
          })}
        </Styled.ButtonList>
      </Styled.Buttons>
    </Styled.Wrapper>
  );
};

export default CollapsedTable;
