import React from "react";
import styled from "styled-components";
import Menu from "components/ui/Menu";
import DisappearingTile from "components/ui/DisappearingTile";
import Tile from "components/ui/Tile";
import UILayout from "components/layout/UILayout";
import { MENU } from "./constants";
import InstitutionSearch from "./InstitutionSearch";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

const Home = () => (
  <UILayout>
    <Wrapper>
      <Tile size="xl">
        <InstitutionSearch />
      </Tile>

      <DisappearingTile size="xl">
        <Menu sections={MENU} />
      </DisappearingTile>
    </Wrapper>
  </UILayout>
);

export default Home;
