import UILabel from "components/ui/UILabel";
import styled, { css } from "styled-components";
import { borderRadius, colors, fontSizesMobile, mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;

  text-align: center;
`;

export const Table = styled.table`
  display: block;
`;

export const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

export const Row = styled.tr`
  position: relative;

  display: flex;
  flex-wrap: wrap;
  --gap: 8px;
  gap: 16px var(--gap);
`;

export const RowHeading = styled.th`
  display: flex;
  width: 100%;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  gap: 8px;

  text-align: center;
`;

export const CellValue = styled(UILabel.XLarge)`
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  font-size: 21px;

  @media screen and (max-width: 360px) {
    font-size: 18px;
  }

  ${(props) =>
    props.$isLarge &&
    css`
      font-size: ${fontSizesMobile.xl}px !important;
    `}

  svg {
    height: 40px;
  }

  sup {
    font-size: 0.66em;
  }
`;

export const CellHeading = styled(UILabel.Small).attrs(() => ({
  bold: true,
  caps: true,
}))`
  min-height: 30px;
  max-width: 120px;
`;

const getCellStyles = ({
  $isReset,
  $highlight,
}: {
  $isReset?: boolean;
  $highlight?: boolean;
}) => {
  if ($isReset) {
    return css`
      background: ${colors.azure};

      ${CellValue} {
        color: ${colors.white};

        svg {
          color: inherit;
        }
      }

      ${CellHeading} {
        color: ${colors.white};
      }
    `;
  }

  if ($highlight) {
    return css`
      background: ${colors.midnight};

      ${CellValue} {
        color: ${colors.cerise};

        svg {
          color: inherit;
        }
      }

      ${CellHeading} {
        color: ${colors.ice};
      }
    `;
  }

  return "";
};

export const CellWrapper = styled.td<{
  $isReset?: boolean;
  $highlight?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 36px;
  width: calc((100% - var(--gap)) / 2);

  border-radius: ${borderRadius.large}px;

  text-align: center;

  ${getCellStyles}

  @media screen and (max-width: 420px) {
    padding: 36px 24px;
  }

  @media screen and (max-width: 360px) {
    padding: 24px 16px;
  }
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ButtonList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  padding: 0;
  margin: 0;

  li {
    list-style-type: none;
  }

  button {
    &,
    * {
      white-space: nowrap;
    }
  }

  ${mediaMax("md")} {
    flex-direction: column;

    li {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }
`;
