import React from "react";
import { ButtonLink } from "components/ui/Button";
import { LinkButton } from "components/ui/Link";
import UILabel from "components/ui/UILabel";
import { getInstructions } from "./utils";
import { Platform } from "./types";
import * as Styled from "./styled";

const Instructions = ({
  platform,
  hideInstructions,
  toggleCardDetails,
  isShownCardDetails,
  isLoadingCardDetails,
}: {
  platform: Platform;
  hideInstructions: () => void;
  toggleCardDetails: () => void;
  isShownCardDetails: boolean;
  isLoadingCardDetails: boolean;
}) => {
  const instructions = getInstructions({
    platform,
    toggleCardDetails,
    isShownCardDetails,
    isLoadingCardDetails,
  });

  return (
    <Styled.InstructionsWrapper>
      <Styled.InstructionsList>
        {instructions.map((item) => (
          <li key={item.toString()}>
            <UILabel.Large>{item}</UILabel.Large>
          </li>
        ))}
      </Styled.InstructionsList>

      <Styled.InstructionsFooter>
        <ButtonLink href="/dashboard">All set!</ButtonLink>

        <LinkButton size="lg" onClick={hideInstructions}>
          Go Back
        </LinkButton>
      </Styled.InstructionsFooter>
    </Styled.InstructionsWrapper>
  );
};

export default Instructions;
