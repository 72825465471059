import { Body, Heading } from "components/ui/Text";
import styled from "styled-components";
import { colors, mediaMax, mediaMin } from "utils/theme";

export const List = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  row-gap: 50px;
  margin-top: 64px;

  text-align: left;

  ${mediaMax("lg")} {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 36px;
  }
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 8px 36px;

  color: ${colors.violet};

  ${mediaMax("lg")} {
    padding: 0;
    flex-direction: row;
    align-items: flex-start;
  }

  ${mediaMax("md")} {
    gap: 16px;
  }

  ${mediaMin("lg")} {
    &:not(:first-child) {
      border-left: 1px solid ${colors.violet}20;
    }
  }
`;

export const ItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  flex: 0 0 auto;

  border-radius: 9999px;

  svg {
    height: 32px;

    * {
      fill: white;
    }
  }

  ${mediaMax("lg")} {
    width: 48px;
    height: 48px;

    svg {
      height: 24px;
    }
  }
`;

export const ItemContent = styled.div`
  display: grid;
  gap: 4px;

  * {
    line-height: 1.33;
  }

  sup {
    color: ${colors.midnight}80;

    line-height: 0;
  }
`;

export const ItemHeading = styled(Heading.H4)``;

export const ItemBody = styled(Body).attrs(() => ({ tag: "div" }))`
  * {
    margin: 0;
  }
`;

export const Wrapper = styled.section`
  padding: 120px 0;

  background: ${colors.white};

  ${mediaMax("lg")} {
    padding: 80px 0;
  }

  ${ListItem} {
    &:nth-child(n + 1) {
      ${ItemIcon} {
        background: linear-gradient(
          -45deg,
          ${colors.heliotrope} 0,
          ${colors.azure} 200%
        );
      }
    }
    &:nth-child(n + 2) {
      ${ItemIcon} {
        background: linear-gradient(
          0deg,
          ${colors.heliotrope} -50%,
          ${colors.azure} 150%
        );
      }
    }
    &:nth-child(n + 3) {
      ${ItemIcon} {
        background: linear-gradient(
          45deg,
          ${colors.heliotrope} -100%,
          ${colors.azure} 100%
        );
      }
    }
  }
`;
