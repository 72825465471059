import UILabel from "components/ui/UILabel";
import React from "react";
import styled from "styled-components";
import { colors } from "utils/theme";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const Product = styled.div`
  padding: 4px 8px;

  border-radius: 3px;
  border: 1px solid ${colors.violet}20;
`;

const ProductList = ({ products }) => (
  <Wrapper>
    {products.map((product) => (
      <Product>
        <UILabel.Medium>{product.replace(/_/g, " ")}</UILabel.Medium>
      </Product>
    ))}
  </Wrapper>
);

export default ProductList;
