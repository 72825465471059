export const getInitialFormValues = () => {
  const params = new URLSearchParams(window.location.search);
  return { user: { reset_password_token: params.get("reset_password_token") } };
};

export const prettifyMessage = (message: string) => {
  let formattedMessage = message;

  if (formattedMessage.indexOf("match") >= 0) {
    formattedMessage = "Passwords must match.";
  }

  if (formattedMessage.charAt(formattedMessage.length - 1) !== ".") {
    formattedMessage += ".";
  }

  return formattedMessage;
};
