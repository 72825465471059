import React from "react";
import confetti from "canvas-confetti";
import { colors, zIndex } from "utils/theme";
import { Platform } from "./types";
import ToggleCardDetailsLink from "./ToggleCardDetailsLink";

export const throwConfetti = () => {
  confetti({
    particleCount: 120,
    shapes: ["circle"],
    colors: [colors.azure, colors.midnight, colors.heliotrope, colors.violet],
    spread: 500,
    origin: { y: 0.4 },
    ticks: 400,
    zIndex: zIndex.modal + 1,
  });
};

export const getInstructions = ({
  platform,
  toggleCardDetails,
  isShownCardDetails,
  isLoadingCardDetails,
}: {
  platform: Platform;
  toggleCardDetails: () => void;
  isShownCardDetails: boolean;
  isLoadingCardDetails: boolean;
}) => {
  switch (platform) {
    case "apple":
      return [
        "Open the Wallet app on your iPhone.",
        "Tap the “+” sign in the upper-right corner.",
        <>
          Follow the prompts to add your Reset card.{" "}
          <ToggleCardDetailsLink
            toggleCardDetails={toggleCardDetails}
            isShownCardDetails={isShownCardDetails}
            isLoadingCardDetails={isLoadingCardDetails}
          />
        </>,
        "Once your card is verified, you're all set to use your card wherever Apple Pay is accepted!",
      ];
    case "google":
      return [
        "Open the Google Wallet app on your Android device.",
        "Tap “Add a card” and choose “Credit or debit card.”",
        <>
          Follow the instructions to enter your Reset card details.{" "}
          <ToggleCardDetailsLink
            toggleCardDetails={toggleCardDetails}
            isShownCardDetails={isShownCardDetails}
            isLoadingCardDetails={isLoadingCardDetails}
          />
        </>,
        "After verification, your card will be ready to use wherever Google Pay is accepted!",
      ];
    default:
      return [];
  }
};
