import styled from "styled-components";
import { colors } from "utils/theme";
import dottedLine from "assets/images/general/dotted-line.svg";

export const ResetLogo = styled.img`
  width: 96px;
`;

export const CellContents = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 96px;
  padding: 24px 8px;
  gap: 10px;

  color: var(--color);

  * {
    max-width: 140px;
  }
`;

export const Divider = styled.div`
  position: relative;

  height: 1px;

  &::before,
  &::after {
    content: "";

    position: absolute;
    left: 0;

    width: 100%;
  }

  &::before {
    top: -1px;

    height: 3px;

    background: ${colors.azure};
  }

  &::after {
    top: 0;

    height: 100%;

    background-image: url("${dottedLine}");
  }
`;

export const DividerRow = styled.tr`
  td {
    &:not(:first-child) {
      ${Divider} {
        &::before {
          background-color: ${colors.ice};
        }
      }
    }

    &:nth-child(2) {
      ${Divider} {
        &::before {
          background-color: ${colors.azure}80;
        }

        &::after {
          background-position: center right;
        }
      }
    }
  }
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  --color: ${colors.midnight};

  th,
  td {
    padding: 0;

    text-align: center;

    &:first-child {
      text-align: left;

      ${CellContents} {
        padding: 0;
        max-width: 180px;
        justify-content: flex-start;
      }
    }
  }

  th {
    ${CellContents} {
      max-width: 160px;
      margin: 0 auto;
    }
  }

  td {
    &:not(:first-child) {
      --color: ${colors.midnight};

      ${CellContents} {
        svg {
          * {
            stroke-width: 12px;
            stroke: currentColor;
          }
        }
      }
    }

    &:nth-child(2) {
      ${CellContents} {
        background: ${colors.azure};

        min-width: 160px;

        svg {
          color: white;
        }
      }
    }
  }

  tr {
    &:first-child {
      td {
        &:nth-child(2) {
          ${CellContents} {
            border-radius: 24px 24px 0 0;
          }
        }
      }
    }

    &:last-child {
      td {
        &:nth-child(2) {
          ${CellContents} {
            border-radius: 0 0 24px 24px;
          }
        }
      }
    }
  }

  sup {
    font-size: 0.66em;
  }
`;
