import styled from "styled-components";
import { colors, fontWeights, mediaMax } from "utils/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  width: 100%;
  padding-top: 24px;

  text-align: center;
`;

export const CardWrapper = styled.div`
  position: relative;
  z-index: 1;

  margin: 0 0 -48px;
  width: calc(100% - 48px);

  ${mediaMax("md")} {
    width: 100%;
    margin: 0 0 -36px;
  }
`;

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  button {
    width: 100%;
  }

  a {
    margin-top: 12px;
  }
`;

export const ButtonIcon = styled.img`
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);

  width: 24px;
`;

export const InstructionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;
  align-items: flex-start;

  text-align: left;
`;

export const InstructionsList = styled.ol`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin: 0;

  list-style-type: none;
  counter-reset: step;

  li {
    position: relative;

    padding-left: 36px;

    counter-increment: step;

    &::before {
      content: counter(step);

      position: absolute;
      top: -3px;
      left: 0;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;

      border-radius: 9999px;
      border: 1px solid ${colors.violet}80;
      color: ${colors.violet};

      font-size: 12px;
      font-weight: ${fontWeights.bold};
    }
  }

  a {
    color: ${colors.azure};

    font-weight: ${fontWeights.bold};
  }
`;

export const InstructionsFooter = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 24px;
  align-items: center;

  a {
    width: 100%;
  }
`;
