import styled, { css } from "styled-components";
import { Eyebrow as BaseEyebrow } from "components/ui/Text";
import { colors, mediaMax } from "utils/theme";

export const CtaWrapper = styled.div`
  display: flex;
  padding-top: 24px;

  a,
  button {
    width: auto;
    min-width: 320px;
    max-width: 100%;
  }
`;

export const Eyebrow = styled(BaseEyebrow)`
  color: var(--color-eyebrow);
`;

interface WrapperProps {
  $isCentered?: boolean;
  $isLight?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${(props) =>
    props.$isLight
      ? `
  --color: ${colors.white};
  --color-highlight: ${colors.heliotrope};
  --color-eyebrow: rgba(255, 255, 255, 0.7);`
      : `
  --color: ${colors.midnight};
  --color-highlight: ${colors.violet};
  --color-eyebrow: ${colors.azure};
  `}

  display: flex;
  flex-direction: column;
  gap: 16px;

  color: var(--color);

  & > * {
    max-width: 768px;
  }

  ${({ $isCentered }) =>
    $isCentered &&
    css`
      align-items: center;
      text-align: center;

      & > * {
        max-width: 768px;
        margin: 0 auto;
      }
    `};

  ${mediaMax("lg")} {
    ${CtaWrapper} {
      justify-content: center;
    }
  }
`;
