import React, { useContext, useEffect, useState } from "react";
import { BlockBackground, PageBlock, PageContent, PageHero } from "types/cms";
import LayoutContext, { LayoutName } from "components/context/LayoutContext";
import ContentLayout from "components/layout/ContentLayout";
import { getSessionPartner } from "utils/partners";
import PartnerBanner from "components/ui/SiteNavigation/PartnerBanner";
import Divider from "../components/Divider";
import hero from "../components/hero";
import block from "../components/block";
import Footnotes from "../components/Footnotes";
import * as Styled from "./styled";

interface Props {
  content: PageContent;
  noLayout?: boolean;
}

const Page: React.FC<Props> = ({ content, noLayout }) => {
  const layout = useContext(LayoutContext);
  const [showPartnerBanner, setShowPartnerBanner] = useState(false);
  const Hero = content.hero
    ? (hero[content.hero.type] as React.FC<PageHero>)
    : null;

  useEffect(() => {
    const sessionPartner = getSessionPartner();
    if (content.partnerSlug && !sessionPartner) {
      // The `PartnerBanner` instantiated by `SiteNav` won't display in this case, since `partner` doesn't exist in `sessionStorage` yet
      setShowPartnerBanner(true);
      sessionStorage.setItem("partner", content.partnerSlug);
    } else {
      setShowPartnerBanner(false);
    }
  }, [content.partnerSlug]);

  return (
    <>
      {showPartnerBanner && <PartnerBanner partnerSlug={content.partnerSlug} />}

      <Styled.Wrapper
        $withSidebar={layout.name === LayoutName.withSidebar}
        $noLayout={noLayout}
      >
        {Hero && <Hero {...content.hero} />}

        <Styled.ContentBlocks>
          {content.blocks.map((contentBlock, index) => {
            const Block = block[contentBlock.type] as React.FC<PageBlock>;
            const nextBlockData = content.blocks[index + 1]?.data;
            const previousBlockData =
              index > 0 && content.blocks[index - 1]?.data;
            const isHero = !Hero && index === 0;
            const isDividerMirrored =
              contentBlock.data.dividerPosition === "topMirrored";

            return Block ? (
              <React.Fragment key={contentBlock.id}>
                {contentBlock.data.dividerPosition && (
                  <Styled.DividerWrapper
                    $background={contentBlock.data.background}
                    $previousBlockBackground={previousBlockData?.background}
                    $isMirrored={isDividerMirrored}
                  >
                    <Divider
                      isGradient={
                        contentBlock.data.background ===
                        BlockBackground.gradient
                      }
                      isMirrored={isDividerMirrored}
                    />
                  </Styled.DividerWrapper>
                )}

                <Styled.ContentBlock
                  $background={contentBlock.data.background}
                  $hasNextBlockDifferentBg={
                    nextBlockData &&
                    !nextBlockData.dividerPosition &&
                    nextBlockData.background !== contentBlock.data.background
                  }
                  $hasNextBlockDivider={
                    nextBlockData && !!nextBlockData.dividerPosition
                  }
                >
                  <ContentLayout>
                    <Block
                      {...contentBlock}
                      isHero={isHero}
                      isReverse={[
                        BlockBackground.dark,
                        BlockBackground.gradient,
                      ].includes(contentBlock.data.background)}
                    />
                  </ContentLayout>
                </Styled.ContentBlock>
              </React.Fragment>
            ) : null;
          })}
        </Styled.ContentBlocks>

        {content.footnotes && (
          <Styled.Footer>
            <Footnotes footnotes={content.footnotes} />
          </Styled.Footer>
        )}
      </Styled.Wrapper>
    </>
  );
};

export default Page;
