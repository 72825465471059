import React, { useEffect, useState } from "react";
import CenteredLayout from "components/layout/CenteredLayout";
import FormContainer from "components/form";
import { editPassword } from "utils/api/user";
import Fields from "./Fields";
import { CurrentUser } from "./types";
import { getInitialFormValues, prettifyMessage } from "./utils";

interface Props {
  currentUser: CurrentUser;
}

const PasswordFields: React.FC<Props> = ({ currentUser }) => {
  const [defaultValues, setDefaultValues] = useState(null);
  const [hasPasswordStrengthError, setHasPasswordStrengthError] =
    useState(false);
  const [apiErrorMessages, setApiErrorMessages] = useState<string[] | null>(
    null
  );

  const showError = () => {
    setHasPasswordStrengthError(true);
    setApiErrorMessages(null);
  };

  const onSubmit = async (values) => {
    setHasPasswordStrengthError(false);

    const res = await editPassword(values);
    const data = await res.json();

    if (res.status === 400) {
      const rawErrorMessages = Object.values(data.errors || {}).flat();
      const formattedMessages = rawErrorMessages.map((message: string) =>
        prettifyMessage(message)
      );
      setApiErrorMessages(formattedMessages);
    } else if (data.location) {
      window.location.pathname = data.location;
    } else {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (!defaultValues) {
      // get hidden inputs from Rails form
      const values = getInitialFormValues();
      setDefaultValues(values);
    }
  }, []);

  if (!defaultValues) return null;

  return (
    <CenteredLayout>
      <FormContainer onSubmit={onSubmit} defaultValues={defaultValues}>
        <Fields
          currentUser={currentUser}
          showError={showError}
          hasPasswordStrengthError={hasPasswordStrengthError}
          apiErrorMessages={apiErrorMessages}
        />
      </FormContainer>
    </CenteredLayout>
  );
};

export default PasswordFields;
