import React, { useEffect } from "react";
import styled from "styled-components";
import Loading from "components/ui/Loading";
import CmsPost from "components/cms/Post";
import NotFound from "components/shared/NotFound";
import { getContentfulPost } from "store/api/apiSlice";

const PageLoading = styled(Loading)`
  min-height: 60vh;
`;

interface Props {
  slug: string;
  preview?: boolean;
  noLayout?: boolean;
}

const Post: React.FC<Props> = ({ slug, preview }) => {
  const { data, isLoading, refetch } = getContentfulPost({ slug, preview });

  useEffect(() => {
    refetch();
  }, [slug]);

  if (isLoading) return <PageLoading />;

  if (!data) return <NotFound />;

  return <CmsPost content={data.post} />;
};

export default Post;
