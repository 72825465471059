import React, { useEffect } from "react";
import styled from "styled-components";
import Loading from "components/ui/Loading";
import CmsPage from "components/cms/Page";
import NotFound from "components/shared/NotFound";
import { getContentfulPage } from "store/api/apiSlice";
import { mapEntryToPage } from "utils/contentful";

const PageLoading = styled(Loading)`
  min-height: 60vh;
`;

interface Props {
  slug: string;
  section?: string;
  preview?: boolean;
  noLayout?: boolean;
}

const Page: React.FC<Props> = ({ slug, section, preview, noLayout }) => {
  const {
    data: pageData,
    isLoading,
    refetch,
  } = getContentfulPage({ slug, section, preview });

  useEffect(() => {
    refetch();
  }, [slug, section]);

  if (isLoading) return <PageLoading />;

  if (!pageData) return <NotFound />;

  const page = mapEntryToPage(pageData);

  return <CmsPage content={page} noLayout={noLayout} />;
};

export default Page;
