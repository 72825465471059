import React, { useState } from "react";
import { OnboardingTaskName } from "types/onboarding";
import { getCardApplicationStatus, getUserProfile } from "store/api/apiSlice";
import DepositSwitching from "./DepositSwitching";
import ConfirmRepaymentAccount from "./ConfirmRepaymentAccount";
import SuccessMessage from "./SuccessMessage";

interface Props {
  id: OnboardingTaskName;
}

const Repayment: React.FC<Props> = ({ id }) => {
  const { refetch: refetchUserProfile } = getUserProfile();
  const [isSuccess, setIsSuccess] = useState(false);
  const { data: cardApplicationStatus } = getCardApplicationStatus({
    skip: !isSuccess,
    pollingInterval: 2000,
  });

  const onSuccess = () => {
    setIsSuccess(true);
    refetchUserProfile();
  };

  if (isSuccess) {
    return (
      <SuccessMessage isDashboardReady={cardApplicationStatus?.complete} />
    );
  }

  switch (id) {
    case OnboardingTaskName.depositSwitching:
      return <DepositSwitching onSuccess={onSuccess} />;
    case OnboardingTaskName.repaymentAccountAcknowledgement:
      return <ConfirmRepaymentAccount onSuccess={onSuccess} />;
    default:
      return <></>;
  }
};

export default Repayment;
