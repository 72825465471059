import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import { Body, BodyLarge } from "components/ui/Text";
import UILabel from "components/ui/UILabel";
import Header from "components/cms/components/Header";
import * as Styled from "./styled";
import QuotationMark from "./QuotationMark";

const TESTIMONIALS = [
  {
    quote: (
      <>
        <BodyLarge>
          Using the Reset card has been super helpful for budgeting.
        </BodyLarge>
        <Body>
          Essentially I have been using it for the day to day and then allowing
          [the] excess from my direct deposit to go into savings. It has been a
          great overall experience and using it in this manner allows me to save
          MUCH more money.&nbsp;&rdquo;
        </Body>
      </>
    ),
    author: "Christy, Healthcare",
  },
  {
    quote: (
      <>
        <BodyLarge>It's been a game changer in managing my finances.</BodyLarge>
        <Body>
          It helps bridge the gap between [paychecks] giving me the peace of
          mind to meet my obligations and handle unexpected expenses. The Reset
          team has created a product that truly solves a real-world problem for
          its users, and I couldn't recommend it more highly.&nbsp;&rdquo;
        </Body>
      </>
    ),
    author: "Ben, Real Estate",
  },
];

const Testimonials = () => (
  <Styled.Wrapper>
    <ContentLayout>
      <Styled.Content>
        <Styled.Main>
          <Header heading="Cardholders love Reset." textSize="large" />
        </Styled.Main>

        <Styled.Testimonials>
          {TESTIMONIALS.map((testimonial, index) => (
            <Styled.Testimonial key={index}>
              <Styled.QuotationMark>
                <QuotationMark />
              </Styled.QuotationMark>

              {testimonial.quote}

              <UILabel.Medium caps bold>
                {testimonial.author}
              </UILabel.Medium>
            </Styled.Testimonial>
          ))}
        </Styled.Testimonials>
      </Styled.Content>
    </ContentLayout>
  </Styled.Wrapper>
);

export default Testimonials;
