import React, { useState } from "react";
import styled from "styled-components";
import { LinkButton } from "components/ui/Link";
import Input, { InputProps } from "../ui/Input";
import FieldContainer from "../FieldContainer";

const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

interface Props extends InputProps {
  label?: React.ReactNode;
}

const PasswordField: React.FC<Props> = ({ name, label, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((s) => !s);
  };

  return (
    <FieldContainer
      name={name}
      label={
        <LabelWrapper>
          <span>{label}</span>
          <LinkButton
            size="md"
            color="azure"
            onClick={togglePasswordVisibility}
          >
            {showPassword ? "Hide" : "Show"}
          </LinkButton>
        </LabelWrapper>
      }
    >
      {({ inputProps, error }) => (
        <Input
          name={name}
          type={showPassword ? "text" : "password"}
          hasError={!!error}
          {...inputProps}
          {...props}
        />
      )}
    </FieldContainer>
  );
};

export default PasswordField;
