import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import useMarqeta from "utils/hooks/useMarqeta";
import { colors } from "utils/theme";
import Modal, { ModalApi } from "../Modal";
import AnimatedResetCard from "./AnimatedResetCard";
import * as Styled from "./styled";
import { Platform } from "./types";
import { throwConfetti } from "./utils";
import Instructions from "./Instructions";
import Overview from "./Overview";

const NewCardModal = (
  {
    onClose,
    showDslZeroModal,
  }: { onClose?: () => void; showDslZeroModal: () => void },
  forwardedRef: React.ForwardedRef<unknown>
) => {
  const ref = useRef<ModalApi>(null);
  const marqeta = useMarqeta({});
  const [showInstructionsFor, setShowInstructionsFor] =
    useState<Platform | null>(null);

  const show = () => {
    setTimeout(() => {
      // allows modal to instantiate, in case show() is called immediately
      throwConfetti();
      ref.current?.show();
    }, 0);
  };

  const showInstructions = (platform: Platform) => () => {
    setShowInstructionsFor(platform);
  };

  const hideInstructions = () => {
    setShowInstructionsFor(null);
    marqeta.hide();
  };

  const toggleCardDetails = () => {
    if (marqeta.isShown) {
      marqeta.hide();
    } else {
      marqeta.show();
    }
  };

  useImperativeHandle(forwardedRef, () => ({ ...ref.current, show }));

  return (
    <>
      <Modal
        ref={ref}
        onClose={onClose}
        overrideCloseBehavior
        header={
          <Styled.CardWrapper>
            <AnimatedResetCard
              marqeta={marqeta}
              showDslZeroModal={showDslZeroModal}
            />
          </Styled.CardWrapper>
        }
      >
        <div
          style={{
            position: "fixed",
            left: 0,
            top: 0,
            zIndex: -1,
            width: "100vw",
            height: "100vh",
            opacity: 0.9,
            background: `linear-gradient(75deg, ${colors.midnight}FF, ${colors.midnight}FF), linear-gradient(75deg, ${colors.azure}, ${colors.heliotrope})`,
          }}
        />

        <Styled.Wrapper>
          {showInstructionsFor ? (
            <Instructions
              hideInstructions={hideInstructions}
              toggleCardDetails={toggleCardDetails}
              isShownCardDetails={marqeta.isShown}
              isLoadingCardDetails={marqeta.isLoading}
              platform={showInstructionsFor}
            />
          ) : (
            <Overview showInstructions={showInstructions} />
          )}
        </Styled.Wrapper>
      </Modal>
    </>
  );
};

export default forwardRef(NewCardModal);
