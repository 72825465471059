import React from "react";
import { BodySmall, Heading } from "components/ui/Text";
import { ButtonLevel, ButtonLink } from "components/ui/Button";
import { getResetConstant } from "utils/reset";
import * as Styled from "./styled";

const Features = () => (
  <Styled.Wrapper>
    <Styled.Main>
      <Heading.H1 tag="h2">Ready to Reset?</Heading.H1>

      <ButtonLink href="/users/sign_up" level={ButtonLevel.cta}>
        Get Started
      </ButtonLink>
    </Styled.Main>

    <Styled.Footnotes>
      <BodySmall>
        <sup>1</sup> {getResetConstant("eligibility") as string}
      </BodySmall>

      <BodySmall>
        <sup>2</sup> Consumer Financial Protection Bureau report: “Data
        Spotlight: Developments in the Paycheck Advance Market” as of July 18,
        2024.
      </BodySmall>

      <BodySmall>
        <sup>3</sup> Experian website: "What is a cash advance fee on a credit
        card ?" as of September 3, 2024.
      </BodySmall>

      <BodySmall>
        <sup>4</sup> Consumer Financial Protection Bureau article: "What is a
        payday loan" as of May 1, 2024.
      </BodySmall>
    </Styled.Footnotes>
  </Styled.Wrapper>
);

export default Features;
