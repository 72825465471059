import React from "react";
import { Heading } from "components/ui/Text";
import backgroundShape from "assets/images/home/section-divider.svg";
import Table from "./Table";
import CollapsedTable from "./CollapsedTable";
import * as Styled from "./styled";

const ComparisonTable = () => (
  <Styled.Wrapper>
    <Styled.BackgroundShape src={backgroundShape} />

    <Styled.Content>
      <Heading.H1 tag="h2">Get more with Reset.</Heading.H1>

      <Styled.TableWrapper>
        <Table />
      </Styled.TableWrapper>

      <Styled.CollapsedTableWrapper>
        <CollapsedTable />
      </Styled.CollapsedTableWrapper>
    </Styled.Content>
  </Styled.Wrapper>
);

export default ComparisonTable;
