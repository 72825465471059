import React, { useState } from "react";
import styled from "styled-components";
import SearchInput from "components/shared/SearchInput";
import {
  searchInstitutions as searchPayroll,
  searchTimeAndAttendance,
} from "utils/api/pinwheel";
import { searchInstitutions as searchBanks } from "utils/api/plaid";
import { Label } from "components/form";
import ProductList from "./ProductList";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const InstitutionSearch = () => {
  const [payroll, setPayroll] = useState(null);
  const [timeAndAttendance, setTimeAndAttendance] = useState(null);
  const [bank, setBank] = useState(null);

  return (
    <Wrapper>
      <Section>
        <Field>
          <Label>Payroll Provider</Label>
          <SearchInput onSelect={setPayroll} getSearch={searchPayroll} />
        </Field>
        {payroll?.data.products && (
          <ProductList products={payroll.data.products} />
        )}
      </Section>

      <Section>
        <Field>
          <Label>Time & Attendance</Label>
          <SearchInput
            onSelect={setTimeAndAttendance}
            getSearch={searchTimeAndAttendance}
          />
        </Field>
        {timeAndAttendance?.data.products && (
          <ProductList products={timeAndAttendance.data.products} />
        )}
      </Section>

      <Section>
        <Field>
          <Label>Financial Institution</Label>
          <SearchInput onSelect={setBank} getSearch={searchBanks} />
        </Field>
        {bank?.data.products && <ProductList products={bank.data.products} />}
      </Section>
    </Wrapper>
  );
};

export default InstitutionSearch;
