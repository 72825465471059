import React from "react";
import Button from "components/ui/Button";
import {
  FormMessage,
  FormSection,
  PasswordField,
  PasswordStrength,
  SubmitButton,
} from "components/form";
import { Body, Heading } from "components/ui/Text";
import { useFormContext, useFormState } from "react-hook-form";
import * as Styled from "./styled";
import PasswordStrengthContainer from "./PasswordStrengthContainer";
import { CurrentUser } from "./types";

interface Props {
  currentUser: CurrentUser;
  showError: () => void;
  hasPasswordStrengthError: boolean;
  apiErrorMessages: string[];
}

const Fields: React.FC<Props> = ({
  currentUser,
  showError,
  hasPasswordStrengthError,
  apiErrorMessages,
}) => {
  const { watch } = useFormContext();
  const form = useFormState();
  const password = watch("user[password]") || "";

  return (
    <Styled.Wrapper>
      <FormSection>
        <Heading.H2 tag="h1">Set your password</Heading.H2>

        <Body>
          Choose a <strong>strong</strong> password to keep your account safe.
        </Body>

        <PasswordStrengthContainer
          password={password}
          blacklist={[currentUser?.first_name, currentUser?.last_name]}
        >
          {({ strength }) => (
            <>
              {hasPasswordStrengthError && strength.score < 4 && (
                <FormMessage>
                  <div>
                    You must choose a <strong>strong</strong> password. Please
                    see requirements below.
                  </div>
                </FormMessage>
              )}

              {apiErrorMessages?.length > 0 && (
                <FormMessage>
                  <div>
                    {apiErrorMessages.map((message) => (
                      <div key={message}>{message}</div>
                    ))}
                  </div>
                </FormMessage>
              )}

              <div>
                <PasswordField
                  label="Password"
                  name="user[password]"
                  placeholder="Password"
                  autoComplete="new-password"
                  required
                />

                <PasswordStrength {...strength} />
              </div>

              <PasswordField
                label="Confirm password"
                name="user[password_confirmation]"
                placeholder="Confirm password"
                required
              />

              {strength.score === 4 ? (
                <SubmitButton
                  type="submit"
                  data-test="btn-submit"
                  disabled={form.isSubmitting}
                >
                  {form.isSubmitting ? "Submitting..." : "Set Password"}
                </SubmitButton>
              ) : (
                <Button type="button" onClick={showError}>
                  Set Password
                </Button>
              )}
            </>
          )}
        </PasswordStrengthContainer>
      </FormSection>
    </Styled.Wrapper>
  );
};

export default Fields;
