import styled from "styled-components";
import { borderRadius, colors, mediaMax, mediaMin } from "utils/theme";

export const Wrapper = styled.section`
  padding: 120px 0;

  background: ${colors.sky};

  ${mediaMax("lg")} {
    padding: 80px 0;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 36px;

  ${mediaMin("lg")} {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16px;
  }
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMax("lg")} {
    text-align: center;
  }

  ${mediaMin("lg")} {
    padding: 48px 32px 0 0;
  }
`;

export const Testimonials = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${mediaMin("lg")} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const Testimonial = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 48px;

  border-radius: ${borderRadius.large}px;

  &:first-child {
    background: ${colors.azure};
  }

  &:nth-child(2) {
    background: ${colors.violet};
  }

  * {
    color: white;
  }

  ${mediaMax("lg")} {
    padding: 36px;
  }

  ${mediaMax("md")} {
    padding: 24px;
  }
`;

export const QuotationMark = styled.div`
  svg {
    width: 48px;

    * {
      fill: white;
    }
  }
`;
