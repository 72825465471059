import React from "react";
import Icon from "components/ui/Icon";
import { colors } from "utils/theme";

const CellContent = ({ text }: { text: React.ReactNode }) => {
  switch (text) {
    case "Yes":
      return <Icon name="checkmark" size="md" color="azure" />;
    case "No":
      return <Icon name="close" size="md" color={`${colors.midnight}80`} />;
    default:
      return text;
  }
};

export default CellContent;
