import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import DslZeroModal from "components/modal/DslZeroModal";
import { Body, Heading } from "components/ui/Text";
import Tile from "components/ui/Tile";
import UILabel from "components/ui/UILabel";
import Loading from "components/ui/Loading";
import { HelpMessage } from "components/form";
import { ModalApi } from "components/modal/Modal";
import NewCardModal from "components/modal/NewCardModal";
import { ButtonLink } from "components/ui/Button";

const Wrapper = styled(Tile)`
  display: flex;
  flex-direction: column;
  gap: 24px;

  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 24px;
  gap: 24px;
`;

interface Props {
  isDashboardReady: boolean;
}

const SuccessMessage: React.FC<Props> = ({ isDashboardReady }) => {
  const newCardModal = useRef<ModalApi>(null);
  const dslZeroModal = useRef<ModalApi>(null);

  useEffect(() => {
    if (isDashboardReady) {
      window.setTimeout(() => {
        newCardModal.current?.show();
      }, 0);
    }
  }, [isDashboardReady]);

  return (
    <>
      {isDashboardReady ? (
        <Wrapper size="xl">
          <Header>
            <Heading.H2 tag="h1">Your Reset card is here!</Heading.H2>
          </Header>

          <Main>
            <Body>
              Please wait while we issue your virtual card and update your
              Dashboard.
            </Body>
          </Main>

          <ButtonLink href="/dashboard">Go to Dashboard</ButtonLink>
        </Wrapper>
      ) : (
        <Wrapper size="xl">
          <Header>
            <Heading.H2 tag="h1">
              Your Reset card is almost&nbsp;ready!
            </Heading.H2>
          </Header>

          <Main>
            <Body>
              Please wait while we issue your virtual card and update your
              Dashboard.
            </Body>
          </Main>

          <Footer>
            <Loading />
            <HelpMessage>
              <UILabel.Medium>Please do not refresh this page.</UILabel.Medium>
            </HelpMessage>
          </Footer>
        </Wrapper>
      )}

      <NewCardModal
        ref={newCardModal}
        showDslZeroModal={dslZeroModal.current?.show}
      />

      <DslZeroModal ref={dslZeroModal} onClose={newCardModal.current?.show} />
    </>
  );
};

export default SuccessMessage;
