import React from "react";
import { INLINES, BLOCKS } from "@contentful/rich-text-types";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import {
  ApiContentfulPageMini,
  ApiContentfulPostMini,
} from "utils/api/contentful";

const getEntityById = (id, entities) =>
  entities.find((entity) => entity.sys.id === id);

const getUrlFromEntry = (
  entry: ApiContentfulPageMini | ApiContentfulPostMini
) => {
  const { slug, typename } = entry;
  const path = [];

  switch (typename) {
    case "Page":
      if (entry.section && entry.section !== "none") {
        path.push(entry.section);
      }
      break;
    case "Post":
      path.push("blog");
      break;
    default:
  }

  path.push(slug);

  return path.join("/");
};

const getInlineEntry = (data, links) => {
  const inlineEntries = links?.entries?.inline;
  const { id } = data.target.sys;
  return getEntityById(id, inlineEntries);
};

const getHyperlinkEntry = (data, links) => {
  const hyperlinkEntries = links?.entries?.hyperlink;
  const { id } = data.target.sys;
  return getEntityById(id, hyperlinkEntries);
};

const getHyperlinkAsset = (data, links) => {
  const hyperlinkAssets = links?.assets?.hyperlink;
  const { id } = data.target.sys;
  return getEntityById(id, hyperlinkAssets);
};

const getBlockAsset = (data, links) => {
  const blockAssets = links?.assets?.block;
  const { id } = data.target.sys;
  return getEntityById(id, blockAssets);
};

export const getDocumentToHtmlStringOptions = (links) => ({
  renderNode: {
    [INLINES.HYPERLINK]: ({ data, content }, next) =>
      `<a href="${data.uri}" data-turbo="false">${next(content)}</a>`,
    [INLINES.ENTRY_HYPERLINK]: ({ data, content }, next) => {
      const entry = getHyperlinkEntry(data, links);
      const path = getUrlFromEntry(entry);
      return `<a href="/${path}" data-turbo="false">${next(content)}</a>`;
    },
    [INLINES.ASSET_HYPERLINK]: ({ data, content }, next) => {
      const asset = getHyperlinkAsset(data, links);
      return `<a href="${asset.url}" target="_blank">${next(content)}</a>`;
    },
    [INLINES.EMBEDDED_ENTRY]: ({ data }) => {
      const entry = getInlineEntry(data, links);
      return `
        <div class="inline-entry">
          <a href="/${getUrlFromEntry(entry)}" data-turbo="false">
            <img src="${entry.thumbnail?.url}" />
            ${entry.title}
          </a>
        </div>
      `;
    },
    [BLOCKS.EMBEDDED_ASSET]: ({ data }) => {
      const asset = getBlockAsset(data, links);
      return `<img src="${asset?.url}" />`;
    },
  },
});

export const RichText = ({ json, links }) =>
  json ? (
    <div
      dangerouslySetInnerHTML={{
        __html: documentToHtmlString(
          json,
          getDocumentToHtmlStringOptions(links)
        ),
      }}
    />
  ) : null;

export const getRichText = (body) => (body ? <RichText {...body} /> : null);
