import React from "react";
import ContentLayout from "components/layout/ContentLayout";
import Header from "components/cms/components/Header";
import Icon from "components/ui/Icon";
import { CardWithIcon } from "types/cms";
import * as Styled from "./styled";
import { FEATURES } from "../../constants";

const Features = () => {
  const { heading, body, tiles } = FEATURES;

  return (
    <Styled.Wrapper>
      <ContentLayout>
        <Header heading={heading} body={body} isCentered textSize="large" />

        <Styled.List>
          {tiles.map((tile: CardWithIcon, index) => (
            <Styled.ListItem key={index}>
              <Styled.ItemIcon>
                <Icon name={tile.iconName} />
              </Styled.ItemIcon>

              {(tile.title || tile.body) && (
                <Styled.ItemContent>
                  {tile.title && (
                    <Styled.ItemHeading>{tile.title}</Styled.ItemHeading>
                  )}
                  {tile.body && <Styled.ItemBody>{tile.body}</Styled.ItemBody>}
                </Styled.ItemContent>
              )}
            </Styled.ListItem>
          ))}
        </Styled.List>
      </ContentLayout>
    </Styled.Wrapper>
  );
};

export default Features;
