import React from "react";
import { RichText } from "utils/contentful/mapEntryToPage/richText";
import Wysiwyg from "components/ui/Wysiwyg";
import { Heading } from "components/ui/Text";
import { ApiContentfulPost } from "utils/api/contentful";
import UILabel from "components/ui/UILabel";
import resetAvatar from "assets/images/general/app-icon-small.svg";
import { formatDateString } from "utils/date";
import * as Styled from "./styled";

interface Props {
  content: ApiContentfulPost;
}

const Post: React.FC<Props> = ({ content }) => {
  const {
    author,
    location,
    sys: { publishedAt },
    publishedDate,
    title,
    body,
  } = content;

  return (
    <>
      <Styled.Wrapper>
        <Styled.Content>
          <Styled.Header>
            <Heading.H2 tag="h1">{title}</Heading.H2>

            <Styled.Info>
              <Styled.Avatar>
                <img
                  src={author?.image?.url || resetAvatar}
                  alt={author?.name || "Team Reset"}
                />
              </Styled.Avatar>

              <Styled.Meta>
                <UILabel.Large>{author?.name || "Team Reset"}</UILabel.Large>

                <Styled.MetaRow>
                  {publishedDate && (
                    <UILabel.Medium color="dust">
                      {formatDateString(publishedDate || publishedAt, "text")}
                    </UILabel.Medium>
                  )}

                  {location && (
                    <UILabel.Medium color="dust">{location}</UILabel.Medium>
                  )}
                </Styled.MetaRow>
              </Styled.Meta>
            </Styled.Info>
          </Styled.Header>

          <Wysiwyg>
            <RichText {...body} />
          </Wysiwyg>
        </Styled.Content>
      </Styled.Wrapper>
    </>
  );
};

export default Post;
