import React from "react";
import { Eyebrow, Heading } from "components/ui/Text";
import Button, { ButtonLevel } from "components/ui/Button";
import Link from "components/ui/Link";
import applePayIcon from "assets/images/general/apple-icon.svg";
import googleWalletIcon from "assets/images/general/google-wallet-icon.svg";
import * as Styled from "./styled";
import { Platform } from "./types";

interface Props {
  showInstructions: (platform: Platform) => () => void;
}

const Overview: React.FC<Props> = ({ showInstructions }) => (
  <>
    <Styled.Heading>
      <Eyebrow>Your Reset card is here</Eyebrow>
      <Heading.H3 tag="h1">
        Start spending on your virtual card instantly.
      </Heading.H3>
    </Styled.Heading>

    <Styled.Footer>
      <Button
        level={ButtonLevel.ghost}
        onClick={showInstructions(Platform.apple)}
      >
        <Styled.ButtonIcon src={applePayIcon} />
        Use With Apple Pay
      </Button>

      <Button
        level={ButtonLevel.ghost}
        onClick={showInstructions(Platform.google)}
      >
        <Styled.ButtonIcon src={googleWalletIcon} />
        Use With Google Wallet
      </Button>

      <Link size="lg" href="/dashboard">
        Go to Dashboard
      </Link>
    </Styled.Footer>
  </>
);

export default Overview;
