import { ButtonProps, LinkProps } from "components/ui/Button";
import { IconName } from "components/ui/Icon";
import { PartnerSlug } from "./general";

// ----------------
// general
// ----------------

export type ImageOrientation = "left" | "right";

export type ImageAnimation = "float";

export type DividerPosition = "top" | "topMirrored";

export enum CtaType {
  button = "BUTTON",
  link = "LINK",
}

export interface ButtonCta {
  type: CtaType.button;
  data: ButtonProps & {
    text: string;
  };
}

export interface LinkCta {
  type: CtaType.link;
  data: LinkProps & {
    text: string;
  };
}

export type Cta = ButtonCta | LinkCta;

export interface Link extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  text: string;
  icon?: IconName;
}

export interface ImageAsset {
  url: string;
  title: string;
}

export enum CardType {
  icon = "ICON",
  image = "IMAGE",
}

export interface CardWithIcon {
  type: CardType.icon;
  title?: string;
  body?: React.ReactNode;
  iconName?: IconName;
  icon?: string;
}

export interface CardWithImage {
  type: CardType.image;
  title?: string;
  body?: React.ReactNode;
  image?: string;
}

export type Card = CardWithIcon | CardWithImage;

export enum BlockBackground {
  white = "WHITE",
  lightBlue = "LIGHT_BLUE",
  gradient = "GRADIENT",
  dark = "DARK",
}

// ----------------
// blocks
// ----------------

export enum BlockType {
  split = "SPLIT",
  tile = "TILE",
  wysiwyg = "WYSIWYG",
  people = "PEOPLE",
  toggle = "TOGGLE",
  logo = "LOGO",
}

interface BaseBlock {
  id: string;
  isHero?: boolean;
  isReverse?: boolean;
}

type TextSize = "large" | "small";

interface BaseBlockData {
  eyebrow?: string;
  heading?: React.ReactNode;
  body?: React.ReactNode;
  textSize?: TextSize;
  cta?: Cta;
  background?: BlockBackground;
  dividerPosition?: DividerPosition;
}

export interface SplitBlock extends BaseBlock {
  type: BlockType.split;
  data: BaseBlockData & {
    image: string;
    imageOrientation?: ImageOrientation;
  };
}

export interface TileBlockData extends BaseBlockData {
  tiles: Card[];
}

export interface TileBlock extends BaseBlock {
  type: BlockType.tile;
  data: TileBlockData;
}

export interface ToggleBlockItem {
  title?: string;
  body?: React.ReactNode;
  iconName?: IconName;
  icon?: string;
}

export interface ToggleBlock extends BaseBlock {
  type: BlockType.toggle;
  data: BaseBlockData & {
    items: ToggleBlockItem[];
  };
}

export interface WysiwygBlock extends BaseBlock {
  type: BlockType.wysiwyg;
  data: BaseBlockData & {};
}

interface Person {
  name: string;
  title?: string;
  image?: string;
  links: { url: string; label: string; icon?: IconName }[];
}

export interface PeopleBlock extends BaseBlock {
  type: BlockType.people;
  data: BaseBlockData & {
    people: Person[];
  };
}

export interface LogoBlock extends BaseBlock {
  type: BlockType.logo;
  data: BaseBlockData & {
    images: ImageAsset[];
  };
}

export type PageBlock =
  | SplitBlock
  | TileBlock
  | WysiwygBlock
  | PeopleBlock
  | ToggleBlock
  | LogoBlock;

// ----------------
// headers
// ----------------

export enum HeroType {
  card = "CARD",
  centered = "CENTERED",
}

export interface CardHero {
  id: string;
  type: HeroType.card;
  data: {
    textSize?: "large" | "small";
    eyebrow?: string;
    heading?: React.ReactNode;
    body?: React.ReactNode;
    image: string;
    imageOrientation?: ImageOrientation;
    imageAnimation?: ImageAnimation;
    cta?: Cta;
    theme?: "default" | "light";
    includePublications?: boolean;
  };
}

export interface CenteredHero {
  id: string;
  type: HeroType.centered;
  data: {
    textSize?: "large" | "small";
    eyebrow?: string;
    heading?: React.ReactNode;
    body?: React.ReactNode;
    cta?: Cta;
    theme?: "default" | "light";
  };
}

export type PageHero = CardHero | CenteredHero;

// ----------------
// page
// ----------------

export interface PageFooter {
  heading: React.ReactNode;
  body?: React.ReactNode;
  ctas?: Cta[];
  links?: Link[];
}

export interface PageContent {
  hero?: PageHero;
  blocks: PageBlock[];
  footer?: PageFooter;
  footnotes?: React.ReactNode[];
  partnerSlug?: PartnerSlug;
}
