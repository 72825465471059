import React, { forwardRef, useImperativeHandle, useState } from "react";
import { getToken, storeIdentifiers } from "utils/api/pinwheel";
import BasePinwheelModal, { LinkResult } from "@pinwheel/react-modal";
import { usePostLogPinwheelEventMutation } from "store/api/apiSlice";

interface Props {
  onSuccess?: (result: LinkResult) => void;
  onSubmit?: (result: LinkResult) => void;
  isDds?: boolean;
  closeOnSuccess?: boolean;
}

export interface ModalApi {
  open: () => void;
}

const PinwheelModal = (
  { onSuccess, onSubmit, isDds, closeOnSuccess }: Props,
  ref: React.ForwardedRef<unknown>
) => {
  const [postLogPinwheelEvent] = usePostLogPinwheelEventMutation();
  const [linkToken, setLinkToken] = useState<string | null>(null);

  const open = async () => {
    const res = await getToken(isDds);
    if (res.success && res.link_token) {
      setLinkToken(res.link_token);
    }
  };

  const onLinkSuccess = async (result) => {
    if (closeOnSuccess) {
      setLinkToken(null);
    }

    if (onSubmit) {
      onSubmit(result);
    }

    await storeIdentifiers(result.accountId, result.platformId);

    if (onSuccess) {
      onSuccess(result);
    }
  };

  const onExit = () => {
    setLinkToken(null);
  };

  const onEvent = (eventName, payload: { [key: string]: any }) => {
    postLogPinwheelEvent({
      eventName,
      eventData: JSON.stringify(payload),
    });
  };

  useImperativeHandle(ref, () => ({ open }));

  return (
    <BasePinwheelModal
      linkToken={linkToken}
      open={!!linkToken}
      onExit={onExit}
      onEvent={onEvent}
      onSuccess={onLinkSuccess}
    />
  );
};

export default forwardRef(PinwheelModal);
