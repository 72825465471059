import LoadingDots from "components/ui/LoadingDots";
import React from "react";
import styled from "styled-components";

const Loading = styled.div`
  transform: translateY(-4px);

  display: inline-block;
  padding-left: 8px;
  margin-left: 4px;

  opacity: 0.5;
`;

const ToggleCardDetailsLink = ({
  toggleCardDetails,
  isShownCardDetails,
  isLoadingCardDetails,
}: {
  toggleCardDetails: () => void;
  isShownCardDetails: boolean;
  isLoadingCardDetails: boolean;
}) => {
  if (isLoadingCardDetails) {
    return (
      <Loading>
        <LoadingDots width={4} />
      </Loading>
    );
  }

  return (
    <a
      href="#"
      onClick={(e) => {
        e.preventDefault();
        toggleCardDetails();
      }}
    >
      {isShownCardDetails ? "Hide" : "Show"} Card Details
    </a>
  );
};

export default ToggleCardDetailsLink;
